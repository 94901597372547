import React, { FC, useState, useRef, MutableRefObject, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@/components'
import * as _ from 'lodash'
import Store from './store'
import { Combobox } from 'react-widgets/cjs'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IAddStateHolidayDto, IStateHolidayDayDto, IStateHolidayDto } from './types'
import DatePicker from 'react-datepicker'
import i18n from '@/utils/i18n'
import moment from 'moment'

interface IView {
  HolidayStore?: Store
  isShow: boolean
  toggleModal: any
}

const AddHoliday: FC<IView> = inject('HolidayStore')(
  observer((props: IView) => {
    const { HolidayStore: store, isShow, toggleModal } = props
    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm()

    const [name, setName] = useState('')

    const [isFullDay, setIsFullDay] = useState(false)

    const [date, setDate] = useState(new Date(`${store!.selectedStateHoliday!.year}-01-01`))

    const submit = async () => {
      const dto: IStateHolidayDayDto = {
        isFullDay: isFullDay,
        date: undefined,
        dateStr: moment(date).format('DD.MM.yyyy'),
        name: name,
        stateHolidayId: store!.selectedStateHoliday!.id,
      }

      await store!.addDayToState(dto)
      toggleModal()
    }

    return (
      <Modal isOpen={isShow} toggle={toggleModal} className="modal-md">
        <ModalHeader toggle={toggleModal}>{t('Add New Holiday')}</ModalHeader>
        <ModalBody>
          <form>
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="form-group w-100">
                  <label htmlFor="name" className="font-weight-bold">
                    {t('Name')}
                  </label>
                  <input
                    type={'text'}
                    id="name"
                    name="name"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="form-group w-100">
                  <label htmlFor="date" className="font-weight-bold">
                    {t('Date')}
                  </label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    locale={i18n.language}
                    className={'form-control mb-0'}
                    id="date"
                    name="date"
                    selected={new Date(date)}
                    onChange={(date: Date) => setDate(date)}
                    autoComplete="off"
                    minDate={new Date(`${store!.selectedStateHoliday!.year}-01-01`)}
                    maxDate={new Date(`${store!.selectedStateHoliday!.year}-12-31`)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="form-group w-100">
                  <label htmlFor="isFullDay" className="font-weight-bold">
                    {t('Is Full Day?')}
                  </label>
                  <select
                    id="isFullDay"
                    name="isFullDay"
                    className="form-control"
                    value={isFullDay ? 'yes' : 'no'}
                    onChange={(e) => setIsFullDay(e.target.value === 'yes')}
                  >
                    <option value="yes">{t('Yes')}</option>
                    <option value="no">{t('No')}</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={false}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default AddHoliday
