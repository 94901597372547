import React, { useState, useEffect, FC, useRef, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import Store from './store'

import { Button, ConfirmModal, Table } from '@/components'
import '@progress/kendo-ui'
import $ from 'jquery'
import moment from 'moment'
import { ConventionalOfferVersionDto } from './types'
import { Link, useHistory } from 'react-router-dom'
import NewVersionPopup from './new-version-popup'

interface IPage {
  ConventionalOfferVersionStore?: Store
  t: any
}

const Page: FC<IPage> = inject('ConventionalOfferVersionStore')(
  observer((props: IPage) => {
    const { ConventionalOfferVersionStore: store, t } = props

    const { records } = store!

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)

    const [selectedRow, setSelectedRow] = useState<ConventionalOfferVersionDto>()

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showNewVersionModal, setShowNewVersionModal] = useState(false)
    const [showLockVersionModal, setShowLockVersionModal] = useState(false)
    const [showObsoleteVersionModal, setShowObsoleteVersionModal] = useState(false)

    useEffect(() => {
      store!.getRecords({ skipCount: 0, maxResultCount: 5 })
    }, [])

    useEffect(() => {
      const gridElement = $('table')
      if (gridElement) {
        kendo.ui.progress(gridElement, isLoading)
      }
    }, [isLoading])

    const detailChooseDeletionMessage = () => {
      if (!selectedRow) {
        return <p>{t('Unknown Error')}</p>
      }

      return (
        <>
          <p>
            {t('Name')}: {selectedRow.name}
          </p>
          <p>
            {t('Explanation')}: {selectedRow.explanation}
          </p>
        </>
      )
    }

    const columns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            const { lockTime } = row
            const isLocked = lockTime != undefined
            return (
              <>
                <Button
                  icon={<i className="fas fa-eye"></i>}
                  tooltipText={t('Details')}
                  onClick={async () => {
                    setSelectedRow(row)
                    history.push(`/digital-offer/conventional-offer-admin/parameters-of-version/${row.id}`)
                  }}
                  className="btn btn-link"
                />
                {!isLocked && (
                  <Button
                    icon={<i className="fas fa-pen"></i>}
                    tooltipText={t('Edit')}
                    onClick={async () => {
                      setSelectedRow(row)
                      setShowNewVersionModal(true)
                    }}
                    className="btn btn-link text-warning"
                  />
                )}
                {!isLocked && (
                  <Button
                    icon={<i className="fas fa-trash"></i>}
                    tooltipText={t('Delete')}
                    onClick={async () => {
                      setSelectedRow(row)
                      setShowDeleteModal(true)
                    }}
                    className="btn btn-link text-danger"
                  />
                )}
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Name'),
          id: 'name',
          accessor: 'name',
          Cell: ({ row }: any) => {
            const { name } = row.values

            return <span dangerouslySetInnerHTML={{ __html: name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Explanation'),
          id: 'explanation',
          accessor: 'explanation',
          Cell: ({ row }: any) => {
            const { explanation } = row.values

            return <span dangerouslySetInnerHTML={{ __html: explanation }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('State'),
          id: 'orgUnit',
          accessor: 'orgUnit',
          Cell: ({ row }: any) => {
            const { orgUnit } = row.values

            return <span dangerouslySetInnerHTML={{ __html: orgUnit?.name ?? '-' }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Last Editor Name'),
          id: 'creatorUserName',
          accessor: 'creatorUserName',
          Cell: ({ row }: any) => {
            const { creatorUserName } = row.values

            return <span dangerouslySetInnerHTML={{ __html: creatorUserName }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Last Edit Time'),
          id: 'createTime',
          accessor: 'createTime',
          Cell: ({ row }: any) => {
            const { createTime } = row.values

            return <span dangerouslySetInnerHTML={{ __html: moment(createTime).format('DD.MM.YYYY - HH:mm') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Is Locked') + '?',
          id: 'isLocked',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            const { lockTime } = row
            const isLocked = lockTime != undefined
            return (
              <Button
                icon={<i className={isLocked ? 'fas fa-check-square' : 'fas fa-square'}></i>}
                tooltipText={t('Lock')}
                disabled={isLocked}
                onClick={async () => {
                  setSelectedRow(row)
                  setShowLockVersionModal(true)
                }}
                className="btn btn-link"
              />
            )
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Is Obsolete') + '?',
          id: 'isObsolete',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            const { id, lockTime, madeObsoleteTime } = row
            const isLocked = lockTime != undefined
            const isObsolete = madeObsoleteTime != undefined
            return (
              <Button
                icon={<i className={isObsolete ? 'fas fa-check-square' : 'fas fa-square'}></i>}
                tooltipText={t('Toggle obsolete state')}
                onClick={async () => {
                  setSelectedRow(row)
                  setShowObsoleteVersionModal(true)
                }}
                className="btn btn-link"
                disabled={!isLocked}
              />
            )
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    const createNewVersion = () => {
      setSelectedRow(undefined)
      setShowNewVersionModal(true)
    }

    return (
      <>
        <div className="card-header">
          <div className="btn-actions-pane-right actions-icon-btn">
            <button className="btn btn-primary btn-shadow flex-direction float-right" onClick={createNewVersion}>
              <i className="fas fa-plus mr-2"></i>
              {t('New Version')}
            </button>
          </div>
        </div>
        <div className="card-body">
          <Table
            smallSize={true}
            columns={columns}
            totalCount={records.totalCount}
            data={records.items}
            fetchData={(x) => store?.getRecords(x)}
            isLoading={!records.isLoaded}
          />
        </div>

        {showDeleteModal && (
          <ConfirmModal
            message={t('The record will be deleted, do you confirm?')}
            detailMessage={detailChooseDeletionMessage()}
            title={t('Warning') + '!'}
            isShow={showDeleteModal}
            toggleModal={() => setShowDeleteModal(!showDeleteModal)}
            onConfirm={async () => {
              await store!.delete(selectedRow!.id)
              setShowDeleteModal(false)
            }}
          />
        )}

        {showLockVersionModal && (
          <ConfirmModal
            message={t('The version will be locked, do you confirm?')}
            detailMessage={detailChooseDeletionMessage()}
            title={t('Warning') + '!'}
            isShow={showLockVersionModal}
            toggleModal={() => setShowLockVersionModal(!showLockVersionModal)}
            onConfirm={async () => {
              await store!.lockVersion(selectedRow!.id)
              setShowLockVersionModal(false)
            }}
          />
        )}

        {showObsoleteVersionModal && (
          <ConfirmModal
            message={t("The version's obsolete state will be changed, do you confirm?")}
            detailMessage={detailChooseDeletionMessage()}
            title={t('Warning') + '!'}
            isShow={showObsoleteVersionModal}
            toggleModal={() => setShowObsoleteVersionModal(!showObsoleteVersionModal)}
            onConfirm={async () => {
              await store!.toggleVersionObsolete(selectedRow!.id)
              setShowObsoleteVersionModal(false)
            }}
          />
        )}

        {showNewVersionModal && (
          <NewVersionPopup
            ConventionalOfferVersionStore={store}
            toggleModal={() => setShowNewVersionModal(!showNewVersionModal)}
            selectedRow={selectedRow}
          />
        )}
      </>
    )
  })
)

export default Page
