import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'

import env from '../../../../config/env'
import http from '../../../../services/http'
import IPagedRequest from '../../../../models/dto/fetch/IPagedRequest'
import IPagedResult from '../../../../models/dto/fetch/IPagedResult'
import { IAddStateHolidayDto, IStateHolidayDayDto, IStateHolidayDto } from './types'
import qs from 'qs'
import { observable } from 'mobx'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'HolidayStore'
  states: []
  baseStateHolidays: []
  stateHolidayDays!: IPagedResult<IStateHolidayDayDto>
  stateHolidays!: IPagedResult<IStateHolidayDto>

  get api() {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.stateHolidays = {
      totalCount: 0,
      items: [],
      isLoaded: true,
    }

    this.states = []
    this.baseStateHolidays = []
    this.getStates = this.getStates.bind(this)
    this.getHolidays = this.getHolidays.bind(this)
    this.getBaseStateHolidays = this.getBaseStateHolidays.bind(this)
  }

  @observable selectedStateHoliday: IStateHolidayDto | null = null

  @action.bound async getStates() {
    this.states = await (await this.api.get(`/api/app/state/all`)).data
  }

  @action.bound async getBaseStateHolidays() {
    this.baseStateHolidays = await (await this.api.get(`/api/app/state-holiday/base-days`)).data
  }

  @action.bound async addStateHoliday(dto: IAddStateHolidayDto) {
    const data = await (await this.api.post(`/api/app/state-holiday`, dto)).data

    this.selectedStateHoliday = data

    this.getStateHolidays()
  }

  @action.bound async addDayToState(dto: IStateHolidayDayDto) {
    const result = await (await this.api.post(`/api/app/state-holiday/add-day`, dto)).data

    this.getHolidays(dto.stateHolidayId)
  }

  @action.bound async deleteStateHoliday() {
    await this.api.delete(`/api/app/state-holiday/${this.selectedStateHoliday!.id}`)
    this.selectedStateHoliday = null
    this.getStateHolidays()
  }

  @action.bound async deleteDay(dto: IStateHolidayDayDto) {
    await this.api.delete(`/api/app/state-holiday/day/${dto.id}`)
    this.getHolidays(dto.stateHolidayId)
  }

  @action.bound async getHolidays(stateHolidayId: number) {
    const response = await this.api.get(`/api/app/state-holiday/days`, {
      params: {
        stateHolidayId: stateHolidayId,
      },
    })

    this.stateHolidayDays = { ...response.data, isLoaded: true }
  }

  @action.bound
  selectStateHoliday(id: number) {
    this.stateHolidays.items = this.stateHolidays.items.map((item) => ({
      ...item,
      isSelected: item.id === id,
    }))

    this.selectedStateHoliday = this.stateHolidays.items.find((item) => item.isSelected) || null
  }

  @action.bound
  async getStateHolidays() {
    const response = await this.api.get(`/api/app/state-holiday/all`)

    this.stateHolidays = { ...response.data, isLoaded: true }

    this.stateHolidays.items = this.stateHolidays.items.map((item, i) => ({
      ...item,
      isSelected: this.selectedStateHoliday ? this.selectedStateHoliday?.id === item.id : i === 0,
    }))

    if (!this.selectedStateHoliday && this.stateHolidays.items.length > 0) {
      this.selectedStateHoliday = this.stateHolidays.items[0]
    }
  }
}

export default Store
