import React, { FC, useState, useMemo, useCallback } from 'react'
import { Switch, Route, Redirect as RouterRedirect, withRouter } from 'react-router-dom'
import * as _ from 'lodash'

import { ProtectedRoute, PublicRoute } from './components'
import SessionRefreshing from './pages/SessionRefreshing'
import Login from './modules/Account/pages/Login/index'
import env from './config/env'

import http from './services/http'
import { getToken } from './utils/token'
import { getPermissions } from './utils/permission'
import { loadRoutes } from './utils/routes'
import PageNotFound from './pages/PageNotFound'

const routes = loadRoutes()

interface IState {
  token: string | null
  isAuthorized: undefined | boolean
  permissions: string[]
}
const { API_BASE_URL } = env

const Routers: FC = (props: any) => {
  const { location, history } = props
  http.defaults.baseURL = API_BASE_URL
  const [state, setState] = useState<IState>({
    token: getToken(),
    isAuthorized: getToken() ? true : false,
    permissions: getPermissions(),
  })
  const isIframe = useMemo(() => window.frameElement || window.self !== window.top || window.location !== window.parent.location, [])

  const mapRoutes: any = useCallback(
    (moduleRoutes: any[], routes: any[]) =>
      _.map(moduleRoutes, (route: any) => {
        if (!isIframe && !state.isAuthorized) return <></>

        if (route.children) return mapRoutes(route.children, routes)

        if (isIframe) return <PublicRoute exact path={route.path} location={location} history={history} routes={routes} {...route} key={route.path} />

        const isGranted = state.isAuthorized && route.permission && _.indexOf(state.permissions, route.permission) >= 0

        if (isGranted)
          return <ProtectedRoute exact path={route.path} location={location} history={history} routes={routes} {...route} key={route.path} />

        debugger
        return <Route exact path="/login" component={Login} />
      }),
    [history, isIframe, location, state.isAuthorized, state.permissions]
  )

  if (location.pathname === '/login') {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
      </Switch>
    )
  }

  if (state.isAuthorized === false) {
    return (
      <Switch>
        <Route exact path={location.pathname} component={Login} />
      </Switch>
    )
  }

  return (
    <Switch>
      {_.map(routes, (module: any) => mapRoutes(module.routes, routes))}
      {state.isAuthorized && (
        <ProtectedRoute
          path="/refresh-session"
          location={location}
          history={history}
          routes={routes}
          component={SessionRefreshing}
          useLayout={false}
        />
      )}
      {console.log('girdim')}
      <Route exact path={location.pathname} component={PageNotFound} />
    </Switch>
  )
}

export default withRouter(Routers)
