import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const PageNotFound: FC = (props: any) => {
  const { t } = useTranslation()

  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-md-12">
          <h1 className="display-1">
            <i className="fas fa-question"></i>
          </h1>
          <div className="d-flex justify-content-start">
            <span className="display-4">{t('Page not found')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
