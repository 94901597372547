import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, NavItem } from 'reactstrap'

import ChangePassword from './changePassword'

import { getUser, purgeUser } from '../../../../../utils/user'
import { purgeToken } from '../../../../../utils/token'
import { purgePermissions } from '../../../../../utils/permission'
import avatar from '../../../../../assets/utils/images/avatars/male.png'

const UserMenu: FC = (props: any) => {
  const { t } = useTranslation()
  const user = getUser()
  const history = useHistory()
  const [showChangePassword, setShowChangePassword] = useState(false)

  const onChangePassword = () => {
    setShowChangePassword(!showChangePassword)
  }

  const onLogout = () => {
    purgeUser()
    purgeToken()
    purgePermissions()
    window.location.href = window.location.origin + '/login'
  }

  return (
    <>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledDropdown>
                <DropdownToggle tag="div" color="link" className="d-flex align-items-center p-0" style={{ cursor: 'pointer' }}>
                  <img width={42} className="rounded-circle" src={avatar} alt={user.name} />
                  <div className="header-user-info ml-3">
                    <div className="widget-heading">{user.name}</div>
                  </div>
                  <i className="fas fa-angle-down opacity-5 text-light ml-2"></i>
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-md">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div className="menu-header-image opacity-2" />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img width={42} className="rounded-circle" src={avatar} alt={user.name} />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">{user.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid-menu grid-menu-2col">
                    <div className="row no-gutters">
                      <div className="col-sm-6">
                        <button
                          className="btn btn-outline-primary btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          onClick={onChangePassword}
                        >
                          <i className="pe-7s-key icon-gradient bg-night-sky btn-icon-wrapper mb-2"> </i>
                          {t('Change Password')}
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button className="btn btn-outline-danger btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2" onClick={onLogout}>
                          <i className="pe-7s-power icon-gradient bg-love-kiss btn-icon-wrapper mb-2"> </i>
                          {t('Logout')}
                        </button>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
      </div>
      {showChangePassword && <ChangePassword isShow={showChangePassword} toggleModal={onChangePassword} />}
    </>
  )
}

export default UserMenu
